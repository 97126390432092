import {Auth, API} from 'aws-amplify';
let nextToken;

const listUsers = async (limit, nextToken) => {
	let apiName = 'AdminQueries';
	let path = '/listUsers';
	let myInit = {
		queryStringParameters: {
			limit: limit,
			token: nextToken,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	const {NextToken, ...rest} = await API.get(apiName, path, myInit);
	nextToken = NextToken;
	return {nextToken, rest};
};
const listUsersInGroup = async (groupname, limit) => {
	let apiName = 'AdminQueries';
	let path = '/listUsersInGroup';
	let myInit = {
		queryStringParameters: {
			groupname: groupname,
			limit: limit,
			token: nextToken,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	const {NextToken, ...rest} = await API.get(apiName, path, myInit);
	nextToken = NextToken;
	return rest;
};

const addUserToGroup = async (username, groupname) => {
	let apiName = 'AdminQueries';
	let path = '/addUserToGroup';
	let myInit = {
		body: {
			username: username,
			groupname: groupname,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	console.log(response);
	return response;
};

const removeUserFromGroup = async (username, groupname) => {
	let apiName = 'AdminQueries';
	let path = '/removeUserFromGroup';
	let myInit = {
		body: {
			username: username,
			groupname: groupname,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	console.log(response);
	return response;
};

const disableUser = async (username) => {
	let apiName = 'AdminQueries';
	let path = '/disableUser';
	let myInit = {
		body: {
			username: username,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	console.log(response);
	return response;
};
const deleteUser = async (username) => {
	console.log(username);
	let apiName = 'AdminQueries';
	let path = '/deleteUser';
	let myInit = {
		body: {
			username: username,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	console.log(response);
	return response;
};

const postUser = async (userName, userEmail, userPassword) => {
	let apiName = 'AdminQueries';
	let path = '/createUser';
	let myInit = {
		body: {
			username: userEmail,
			name: userName,
			password: userPassword,
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	return response;
};

const getUser = async (username) => {
	console.log(username);
	let apiName = 'AdminQueries';
	let path = '/getUser';
	let myInit = {
		body: {username: username},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `${(await Auth.currentSession())
				.getAccessToken()
				.getJwtToken()}`,
		},
	};
	let response = await API.post(apiName, path, myInit);
	console.log(response);
	return response;
};

export {
	listUsers,
	listUsersInGroup,
	addUserToGroup,
	removeUserFromGroup,
	disableUser,
	deleteUser,
	postUser,
	getUser,
};
