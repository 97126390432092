import * as React from '../Components/ChildCard';
import {formatDate} from '../lib/helpers';
import {ChildItem} from '../Global/StyledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChild, faBaby} from '@fortawesome/free-solid-svg-icons';

const ChildCard = ({child, children}) => {
	function calculateAge(birthday) {
		// birthday is a date
		var ageDifMs = Date.now() - birthday.getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}
	return (
		<ChildItem>
			{child.pregnancy ? (
				<FontAwesomeIcon icon={faBaby} />
			) : (
				<FontAwesomeIcon icon={faChild} />
			)}
			<div>
				<p>
					{child.firstName} {child.lastName}
				</p>
				{child.pregnancy ? (
					<div>
						<span>Due Date:</span>
						<span> {formatDate(new Date(child.dueDate))}</span>
					</div>
				) : (
					<div>
						<span>Age:</span>{' '}
						<span>{calculateAge(new Date(child.birthdate))}</span>
					</div>
				)}
			</div>
			{children}
		</ChildItem>
	);
};

export default ChildCard;
