/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useAuthState, useAuthDispatch} from '../Context/context';
import {Link} from 'react-router-dom';

import {
	ContentContainer,
	DisplayItem,
	ClientData,
	ClientAside,
	ColumnHeader,
	ButtonsColumn,
	DashboardButton,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';

import NoteCard from '../Components/NoteCard';
import {checkToken} from '../Context/actions';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';

const Home = () => {
	const {user} = useAuthState();
	const dispatch = useAuthDispatch();
	const [clients, setClients] = React.useState();
	const [programs, setPrograms] = React.useState();
	const [recentNotes, setRecentNotes] = React.useState([]);

	async function getClients() {
		const response = await API.graphql({
			query: queries.listClients,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	// async function getPrograms() {
	// 	const response = await API.graphql({
	// 		query: queries.listPrograms,
	// 		authMode: 'AMAZON_COGNITO_USER_POOLS',
	// 	});
	// 	return response;
	// }

	async function getRecentNotes() {
		const response = await API.graphql({
			query: queries.notesByDate,
			variables: {
				type: 'note',
				sortDirection: 'DESC',
				limit: 10,
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setRecentNotes(response.data.notesByDate.items);
		return response;
	}

	React.useEffect(() => {
		const clientData = getClients();
		clientData
			.then((res) => setClients(res.data.listClients.items))
			.catch((err) => checkToken());

		const recentNotes = getRecentNotes();
		recentNotes
			.then((res) => console.log(res))
			.catch((err) => checkToken());
		// const programData = getPrograms();
		// programData.then((res) => setPrograms(res.data.listPrograms.items));
	}, []);
	return !user ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<h1>Dashboard</h1>
			<p>Hello {user.attributes.name}</p>
			<ClientData>
				<ButtonsColumn>
					<DashboardButton>
						<Link to={`/add-client`}>
							<FontAwesomeIcon icon={faPlus} />
							<span>Add Client</span>
						</Link>
					</DashboardButton>
				</ButtonsColumn>
				<ClientAside>
					<ColumnHeader>
						<h2>Notes</h2>
					</ColumnHeader>
					<div>
						{recentNotes.map((note, key) => {
							return <NoteCard key={key} note={note} />;
						})}
					</div>
				</ClientAside>
			</ClientData>
		</ContentContainer>
	);
};

export default Home;
