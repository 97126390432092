import * as React from 'react';
import {months} from '../lib/helpers';

import {DetailItem, FieldWrapper, FormGroup} from '../Global/StyledComponents';
import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import {Controller} from 'react-hook-form';

import {TextField, MenuItem} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarWeek} from '@fortawesome/pro-regular-svg-icons';

const DatePicker = ({groupLabel, initialValue, form, nameObj}) => {
	const {control} = form;
	const currentMonth = initialValue.length
		? months.find((x) => x.name === initialValue.month)
		: {
				id: 0,
				name: 'January',
				length: 31,
		  };
	const defaultMonth = initialValue ? initialValue.month : '';
	const defaultDay = initialValue ? initialValue.day : '';
	const defaultYear = initialValue ? initialValue.year : '';

	return (
		<FormGroup>
			<p>{groupLabel}</p>
			<DetailItem>
				<FontAwesomeIcon icon={faCalendarWeek} size="lg" />
				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="month-field"
						name={nameObj.month}
						label="Month"
						defaultValue={defaultMonth || ''}
						variant="outlined">
						<MenuItem value="January">January</MenuItem>
						<MenuItem value="February">February</MenuItem>
						<MenuItem value="March">March</MenuItem>
						<MenuItem value="April">April</MenuItem>
						<MenuItem value="May">May</MenuItem>
						<MenuItem value="June">June</MenuItem>
						<MenuItem value="July">July</MenuItem>
						<MenuItem value="August">August</MenuItem>
						<MenuItem value="September">September</MenuItem>
						<MenuItem value="October">October</MenuItem>
						<MenuItem value="November">November</MenuItem>
						<MenuItem value="December">December</MenuItem>
					</ReactHookFormSelect>
				</FieldWrapper>

				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="day-field"
						name={nameObj.day}
						label="Day"
						defaultValue={defaultDay || ''}
						variant="outlined">
						{[...Array(currentMonth.length).keys()].map((key) => {
							return (
								<MenuItem key={key} value={key + 1}>
									{key + 1}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</FieldWrapper>
				<FieldWrapper>
					<Controller
						control={control}
						name={nameObj.year}
						defaultValue={defaultYear || ''}
						render={({field}) => (
							<TextField
								label="Year"
								variant="outlined"
								autoComplete="foo"
								type="text"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
		</FormGroup>
	);
};

export default DatePicker;
