/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	ContentContainer,
	DashboardTitle,
	ItemList,
	ItemListTitles,
	ItemRow,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import Notify from '../Components/Notify';

import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import SpecialServicesTypeForm from '../Forms/SpecialServicesTypeForm';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faEdit, faPlus, faBan} from '@fortawesome/pro-regular-svg-icons';
import {checkToken} from '../Context/actions';

const SpecialServicesDashboard = () => {
	const [specialServices, setSpecialServices] = React.useState();
	const [
		selectedSpecialService,
		setSelectedSpecialService,
	] = React.useState();
	const [refreshSpecialServices, setRefreshSpecialServices] = React.useState(
		false
	);
	const [activeView, setActiveView] = React.useState('specialServices');
	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const specialServicesForm = useForm({
		defaultValues: selectedSpecialService,
	});

	async function getSpecialServices() {
		const response = await API.graphql({
			query: queries.listSpecialServiceTypes,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	function deleteSpecialService(specialServiceId, notify) {
		function cancelAction() {
			setShowNotify(false);
		}
		const notifyData = {
			...notify,
			cancel: cancelAction,
			action: deleteAction,
		};

		setNotifyProps(notifyData);
		setShowNotify(true);

		async function deleteAction() {
			const data = {
				id: specialServiceId,
			};
			console.log(data);
			const response = await API.graphql({
				query: mutations.deleteSpecialServiceType,
				variables: {input: data},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			});
			if (
				response.data.deleteSpecialServiceType.id === specialServiceId
			) {
				setShowNotify(false);
				setRefreshSpecialServices(true);
			}
			return response;
		}
	}

	React.useEffect(() => {
		const specialServiceData = getSpecialServices();
		specialServiceData
			.then((res) =>
				setSpecialServices(res.data.listSpecialServiceTypes.items)
			)
			.catch((err) => checkToken());
		console.log(specialServices);
		setRefreshSpecialServices(false);
	}, [refreshSpecialServices]);

	return !specialServices ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'specialServices' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>Special Services</h1>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) =>
									setActiveView('addSpecialService')
								}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add Special Service</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>

					<ItemList>
						<ItemListTitles>
							<div className="item">
								<p>Name</p>
							</div>
							<div className="item">
								<p>Description</p>
							</div>
							<div className="item">
								<p>Actions</p>
							</div>
						</ItemListTitles>
						{specialServices.map((specialService, key) => (
							<ItemRow key={key}>
								<div className="item">
									<p>{specialService.name}</p>
								</div>
								<div className="item">
									<p>{specialService.description}</p>
								</div>

								<div className="action">
									<button
										type="button"
										title="Edit Special Service"
										onClick={() => {
											setSelectedSpecialService(
												specialServices
											);
											setActiveView('editSpecialService');
										}}>
										<FontAwesomeIcon icon={faEdit} />
									</button>
									<button
										type="button"
										title="Delete SpecialService"
										onClick={() => {
											deleteSpecialService(
												specialService.id,
												{
													message:
														'Are you sure you want to delete this SpecialService?',
													confirm: 'Delete',
													cancel: 'Cancel',
													action: {},
												}
											);
										}}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</button>
								</div>
							</ItemRow>
						))}
					</ItemList>
				</>
			) : null}
			{activeView === 'addSpecialService' ? (
				<>
					<ColumnHeader>
						<h1>Add Special Service</h1>
						<button
							type="button"
							onClick={(e) => {
								specialServicesForm.reset();
								setActiveView('specialServices');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<SpecialServicesTypeForm
						setActiveView={setActiveView}
						specialServicesForm={specialServicesForm}
						setRefreshSpecialServices={() => {
							setRefreshSpecialServices(true);
						}}
					/>
				</>
			) : null}
			{activeView === 'editSpecialService' ? (
				<>
					<ColumnHeader>
						<h1>Edit Special Service</h1>
						<button
							type="button"
							onClick={(e) => {
								specialServicesForm.reset();
								setActiveView('specialServices');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<SpecialServicesTypeForm
						setActiveView={setActiveView}
						editMode={true}
						specialService={selectedSpecialService}
						specialServicesForm={specialServicesForm}
						setRefreshSpecialServices={() => {
							setRefreshSpecialServices(true);
						}}
					/>
				</>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default SpecialServicesDashboard;
