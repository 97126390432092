import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faSpinner} from '@fortawesome/free-solid-svg-icons';

import {SearchContainer, SearchForm} from '../Global/StyledComponents';

const SearchBar = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [input, setInput] = React.useState('');
	const [err, setErr] = React.useState('');

	const history = useHistory();

	const submitSearch = async () => {
		setIsLoading(true);
		history.push(`/clients?search=${input}`);
		setInput('');
		setIsLoading(false);
	};

	return (
		<SearchContainer>
			<SearchForm
				err={err}
				onSubmit={(e) => {
					e.preventDefault();
					if (input) {
						submitSearch();
					} else {
						setErr('Enter a search term');
					}
				}}>
				<input
					onClick={() => setErr('')}
					type="text"
					value={input}
					placeholder={err ? err : 'Search'}
					onChange={(e) => setInput(e.target.value)}
				/>
				<button type="submit">
					{isLoading ? (
						<FontAwesomeIcon icon={faSpinner} spin />
					) : (
						<FontAwesomeIcon icon={faSearch} />
					)}
				</button>
			</SearchForm>
		</SearchContainer>
	);
};

export default SearchBar;
