import * as React from 'react';
import {postUser} from './AdminAPIs';

import {useHistory} from 'react-router-dom';

import {Controller} from 'react-hook-form';
import {TextField, Button} from '@material-ui/core';
import Loader from '../Components/Loader';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';

const CreateUserForm = ({form}) => {
	const [loading, setLoading] = React.useState(false);
	const {control, handleSubmit} = form;
	const history = useHistory();

	const onSubmit = async (data) => {
		setLoading(true);
		let {name, email, password} = data;
		let newUser = await postUser(name, email, password);
		if (newUser.message === `Created user account for ${email}`) {
			localStorage.removeItem('adminUsersCache');
			history.push(`/admin`);
		}
	};

	return loading ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DetailItem>
					<FieldWrapper>
						<Controller
							name="name"
							control={control}
							render={({field}) => (
								<TextField
									label="Name"
									variant="outlined"
									defaultValue=""
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FieldWrapper>
						<Controller
							name="email"
							control={control}
							render={({field}) => (
								<TextField
									label="Email"
									variant="outlined"
									defaultValue=""
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FieldWrapper>
						<Controller
							name="password"
							control={control}
							render={({field}) => (
								<TextField
									label="Temporary Password"
									variant="outlined"
									defaultValue=""
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disableElevation>
					Save
				</Button>
			</section>
		</Form>
	);
};

export default CreateUserForm;
