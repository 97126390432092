import * as React from 'react';
import LogRocket from 'logrocket';
import {useAuthState} from './Context/context';
import {Redirect, Switch, Route} from 'react-router-dom';

import {ContentContainer} from './Global/StyledComponents';
import Loader from './Components/Loader';

import Home from './Pages/Home';
import AdminDashboard from './Pages/AdminDashboard';
import ClientsDashboard from './Pages/ClientsDashboard';
import ReportsDashboard from './Pages/ReportsDashboard';
import SpecialServiceDashboard from './Pages/SpecialServiceDashboard';
import HelpsDashboard from './Pages/HelpsDashboard';
import PillarsDashboard from './Pages/PillarsDashboard';
import IncentivesDashboard from './Pages/IncentivesDashboard';
import ClientDetail from './Pages/ClientDetail';
import NewClient from './Pages/NewClient';
import NewUser from './Pages/NewUser';

import Import from './Pages/Import';

const Root = ({clientsSearch, setClientsSearch}) => {
	const {user} = useAuthState();
	// console.log(user);
	user &&
		LogRocket.identify(user.attributes.email, {
			name: user.attributes.name,
			email: user.attributes.email,
			username: user.username,
		});

	const PrivateRoute = ({children, group, ...rest}) => {
		const groups = user.signInUserSession.idToken.payload['cognito:groups'];
		const isInGroup = (userGroup) => {
			return groups && groups.includes(userGroup);
		};
		return (
			<Route
				{...rest}
				render={({location}) => {
					return isInGroup(group) ? (
						children
					) : (
						<Redirect
							to={{
								pathname: '/dashboard',
								state: {from: location},
							}}
						/>
					);
				}}
			/>
		);
	};

	return !user ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<>
			<Switch>
				<Route path="/app/login">
					{/* <SignInForm /> */}
					<Redirect
						to={{
							pathname: '/',
						}}
					/>
				</Route>
				<Route path="/dashboard">
					<Home />
				</Route>
				<Route path="/add-client">
					<NewClient />
				</Route>
				<Route path="/clients">
					<ClientsDashboard
						clientsSearch={clientsSearch}
						setClientsSearch={setClientsSearch}
					/>
					<Route path={`/clients/:clientId`}>
						<ClientDetail />
					</Route>
				</Route>
				<PrivateRoute path="/special-services" group="admins">
					<SpecialServiceDashboard />
				</PrivateRoute>
				<PrivateRoute path="/helps" group="admins">
					<HelpsDashboard />
				</PrivateRoute>
				<PrivateRoute path="/pillars" group="admins">
					<PillarsDashboard />
				</PrivateRoute>
				<PrivateRoute path="/incentives" group="admins">
					<IncentivesDashboard />
				</PrivateRoute>
				<PrivateRoute path="/reports" group="admins">
					<ReportsDashboard />
				</PrivateRoute>
				<PrivateRoute path="/admin" group="admins">
					<AdminDashboard />
				</PrivateRoute>
				<PrivateRoute path="/add-user" group="admins">
					<NewUser />
				</PrivateRoute>
				<PrivateRoute path="/import" group="admins">
					<Import />
				</PrivateRoute>
				<Route exact path="/">
					<Redirect
						to={{
							pathname: '/dashboard',
						}}
					/>
				</Route>
			</Switch>
		</>
	);
};

export default Root;
