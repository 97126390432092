import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	Form,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {Button, MenuItem} from '@material-ui/core';

import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import DatePicker from '../Components/DatePicker';
import {toDateString, fromDateString} from '../lib/helpers';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLandmark} from '@fortawesome/free-solid-svg-icons';
import {checkToken} from '../Context/actions';

const HelpForm = ({client, help, helpForm, setActive, setActivityList}) => {
	const [helpTypes, setHelpTypes] = React.useState();
	const {control, handleSubmit} = helpForm;

	async function getHelps() {
		const response = await API.graphql({
			query: queries.listHelpTypes,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}
	React.useEffect(() => {
		const helps = getHelps();
		helps
			.then((res) => setHelpTypes(res.data.listHelpTypes.items))
			.catch((err) => checkToken());
	}, []);

	async function createHelp(newHelp) {
		const response = await API.graphql({
			query: mutations.createHelp,
			variables: {input: newHelp},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			helpForm.reset();
			setActive('client');
			setActivityList(false);
		}

		return response;
	}

	const onSubmit = (data) => {
		let helpValues = data;
		helpValues.clientID = client.id;

		helpValues.date = toDateString(
			data.helpmonth,
			data.helpday,
			data.helpyear
		);
		helpValues.helpName = helpTypes.filter(
			(item) => item.id === helpValues.helpID
		)[0].name;
		let parsed = Object.keys(helpValues)
			.filter((k) => helpValues[k] != null)
			.reduce((a, k) => ({...a, [k]: helpValues[k]}), {});

		delete parsed.helpmonth;
		delete parsed.helpday;
		delete parsed.helpyear;
		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;

		console.log(parsed);
		createHelp(parsed);
	};
	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<section>
				<DatePicker
					form={helpForm}
					groupLabel="Completion Date"
					initialValue={
						(help && fromDateString(help.date)) || {
							month: 'January',
						}
					}
					nameObj={{
						month: 'helpmonth',
						day: 'helpday',
						year: 'helpyear',
					}}
				/>
				<DetailItem>
					<FontAwesomeIcon icon={faLandmark} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="item-field"
							name="helpID"
							label="Help Type"
							defaultValue={(help && help.helpID) || ''}
							variant="outlined">
							{helpTypes &&
								helpTypes.map((type, key) => (
									<MenuItem key={key} value={type.id}>
										{type.name}
									</MenuItem>
								))}
						</ReactHookFormSelect>
					</FieldWrapper>
				</DetailItem>
			</section>
			<Button
				type="submit"
				variant="contained"
				color="primary"
				disableElevation>
				Save
			</Button>
		</Form>
	);
};

export default HelpForm;
