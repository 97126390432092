// Reports needed:

// Monthly-
// Number of new moms
// Number of new babies
// Number of layette
// Number of moms in class
// Number of videos watched
// Number of volunteer hours
// Number of store visits

// Yearly-
// Ethnicity
// Age
// Level of education
// Number of diapers given out
// Amount of formula given out
// Total number of services provided

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {useAuthState} from '../Context/context';

import {ContentContainer} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import {API} from 'aws-amplify';
import * as queries from '../graphql/queries';
import {checkToken} from '../Context/actions';

const ReportsDashboard = () => {
	const {user} = useAuthState();
	const [clients, setClients] = React.useState();
	const [programs, setPrograms] = React.useState();
	const [recentNotes, setRecentNotes] = React.useState([]);

	async function getClients() {
		const response = await API.graphql({
			query: queries.listClients,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	// async function getPrograms() {
	// 	const response = await API.graphql({
	// 		query: queries.listPrograms,
	// 		authMode: 'AMAZON_COGNITO_USER_POOLS',
	// 	});
	// 	return response;
	// }

	async function getRecentNotes() {
		const response = await API.graphql({
			query: queries.notesByDate,
			variables: {
				type: 'note',
				sortDirection: 'DESC',
				limit: 10,
			},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setRecentNotes(response.data.notesByDate.items);
		return response;
	}

	React.useEffect(() => {
		const clientData = getClients();
		clientData
			.then((res) => setClients(res.data.listClients.items))
			.catch((err) => checkToken());

		const recentNotes = getRecentNotes();
		recentNotes
			.then((res) => console.log(res))
			.catch((err) => checkToken());
		// const programData = getPrograms();
		// programData.then((res) => setPrograms(res.data.listPrograms.items));
	}, []);
	return !user ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			<h1>Reports</h1>
			<h2>Coming Soon...</h2>
		</ContentContainer>
	);
};

export default ReportsDashboard;
