/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import {
	formatPhoneNumber,
	formatDate,
	fromDateString,
	formatShortDate,
} from '../lib/helpers';

import {useAuthState} from '../Context/context';
import {checkToken} from '../Context/actions';
import {
	ActivityDeleteButton,
	ContentContainer,
	DashboardTitle,
	DisplayItem,
	ClientEditHeader,
	ClientData,
	ClientAddChild,
	ChildColumn,
	ClientAside,
	ClientActionButton,
	ColumnHeader,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import ChildCard from '../Components/ChildCard';
import NoteCard from '../Components/NoteCard';
import API from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import {useForm} from 'react-hook-form';
import CreateClientForm from '../Forms/ClientForm';
import ChildFormComponent from '../Forms/ChildForm';
import NoteFormComponent from '../Forms/NoteForm';
import ClassFormComponent from '../Forms/ClassForm';
import VideoFormComponent from '../Forms/VideoForm';
import HelpFormComponent from '../Forms/EmergencyHelpForm';
import ServiceFormComponent from '../Forms/SpecialServiceForm';

import Notify from '../Components/Notify';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPhone,
	faEnvelope,
	faGraduationCap,
	faPlus,
	faBan,
	faVideo,
	faHandshake,
	faGift,
} from '@fortawesome/free-solid-svg-icons';
import {
	faEdit,
	faAddressBook,
	faCreditCardBlank,
	faRingsWedding,
	faUniversity,
	faCalendarWeek,
	faSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faUserFriends} from '@fortawesome/pro-duotone-svg-icons';

const ClientDetail = () => {
	const [client, setClient] = React.useState();
	const [clientId, setClientId] = React.useState();
	const [refreshClient, setRefreshClient] = React.useState(false);
	const [activeView, setActiveView] = React.useState('client');
	const [selectedChild, setSelectedChild] = React.useState({});
	const [selectedNote, setSelectedNote] = React.useState({});
	const [selectedClass, setSelectedClass] = React.useState({});
	const [selectedVideo, setSelectedVideo] = React.useState({});
	const [selectedHelp, setSelectedHelp] = React.useState({});
	const [selectedService, setSelectedService] = React.useState({});
	const [activityList, setActivityList] = React.useState(false);

	const [notifyProps, setNotifyProps] = React.useState({
		message: '',
		confirm: '',
		cancel: {},
		action: {},
	});
	const [showNotify, setShowNotify] = React.useState(false);

	const [activities, setActivities] = React.useState([]);

	const {user} = useAuthState();
	// console.log(user);
	const [currentUser, setCurrentUser] = React.useState({
		username: user.username,
		name: user.signInUserSession.idToken.payload.name,
	});

	async function getClient(clientId) {
		const response = await API.graphql({
			query: queries.getClient,
			variables: {id: clientId},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		return response;
	}

	async function updateLayette(layette) {
		const attrs = {
			id: clientId,
			layette: layette,
		};
		const response = await API.graphql({
			query: mutations.updateClient,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setClient({...client, layette: response.data.updateClient.layette});
		setRefreshClient(true);
		return response;
	}

	async function deleteClass(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteClass,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteVideo(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteVideo,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteHelp(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteHelp,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}
	async function deleteSpecialService(id) {
		const attrs = {
			id: id,
		};
		const response = await API.graphql({
			query: mutations.deleteSpecialService,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		setShowNotify(false);
		setRefreshClient(true);
		return response;
	}

	function showEditMode(e) {
		// console.log(client);
		setActiveView('edit');
		// setEditMode(!editMode);
		// console.log(editMode);
	}
	function showAddChild(e) {
		setActiveView('addChild');
	}

	const form = useForm({
		defaultValues: client,
	});
	const childForm = useForm({
		defaultValues: selectedChild,
	});
	const noteForm = useForm({
		defaultValues: selectedNote,
	});
	const classForm = useForm({
		defaultValues: selectedClass,
	});
	const videoForm = useForm({
		defaultValues: selectedVideo,
	});
	const helpForm = useForm({
		defaultValues: selectedHelp,
	});
	const serviceForm = useForm({
		defaultValues: selectedService,
	});

	React.useEffect(() => {
		const currentClient = window.location.pathname.split('/clients/')[1];
		setClientId(currentClient);
		const clientData = getClient(currentClient);
		clientData
			.then((res) => {
				const data = res.data.getClient;
				console.log(data);
				const modifiedDate = formatDate(new Date(data.updatedAt));
				const formattedPhone = formatPhoneNumber(data.phone);

				const birthdateObj = data.birthdate
					? fromDateString(data.birthdate)
					: {};

				const allActivities = [
					...data.classes.items.map((item) => {
						let el = item;
						el.activityType = 'class';
						return el;
					}),
					...data.videos.items.map((item) => {
						let el = item;
						el.activityType = 'video';
						return el;
					}),
					...data.helps.items.map((item) => {
						let el = item;
						el.activityType = 'help';
						return el;
					}),
					...data.specialServices.items.map((item) => {
						let el = item;
						// console.log(el);
						el.activityType = 'service';
						return el;
					}),
				];

				if (data.layette) {
					allActivities.push({
						activityType: 'layette',
						layette: data.layette,
					});
				}
				// console.log(allActivities);
				setActivities(allActivities);

				setClient({
					...res.data.getClient,
					modified: modifiedDate,
					phone: formattedPhone,
					birthmonth: birthdateObj.month,
					birthday: birthdateObj.day,
					birthyear: birthdateObj.year,
					birthObj: birthdateObj,
					activities: allActivities,
				});
				form.reset(client);
			})
			.catch((err) => checkToken());
		setRefreshClient(false);
	}, [activeView, refreshClient, form.reset]);

	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ContentContainer>
			{activeView === 'client' ? (
				<>
					<DashboardTitle>
						<div>
							<h1>
								{client.firstName} {client.lastName}
							</h1>
							<p style={{fontStyle: 'italic'}}>
								Last updated: {client.modified}
							</p>
						</div>
						<div>
							<ClientActionButton
								type="button"
								onClick={(e) => {
									console.log(e);
									setActiveView('addNote');
								}}>
								<FontAwesomeIcon icon={faPlus} />
								<span>Add Note</span>
							</ClientActionButton>
						</div>
					</DashboardTitle>
					<ClientData>
						<section>
							<ColumnHeader>
								<h2>Profile</h2>
								<button
									type="button"
									onClick={(e) => {
										showEditMode(e);
									}}>
									<FontAwesomeIcon icon={faEdit} />
									<span>Edit</span>
								</button>
							</ColumnHeader>
							<DisplayItem>
								<FontAwesomeIcon icon={faPhone} />
								<div>
									<span>Phone</span>
									<p>{client.phone}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon icon={faEnvelope} />
								<div>
									<span>Email</span>
									<p>{client.email}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faCalendarWeek}
									size="lg"
								/>
								<div>
									<span>Date of Birth</span>
									{client.birthdate ? (
										<p>
											{client.birthmonth}{' '}
											{client.birthday},{' '}
											{client.birthyear}
										</p>
									) : null}
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faAddressBook}
									size="lg"
								/>
								<div>
									<span>Address</span>
									<p>
										{client.address} <br />
										{client.city}, {client.state}{' '}
										{client.zip}
									</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faRingsWedding}
									size="lg"
								/>
								<div>
									<span>Marital Status</span>
									<p>{client.maritalStatus}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faUserFriends}
									size="lg"
								/>
								<div>
									<span>Ethnicity</span>
									<p>{client.ethnicity}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faUniversity}
									size="lg"
								/>
								<div>
									<span>Education</span>
									<p>{client.education}</p>
								</div>
							</DisplayItem>
							<DisplayItem>
								<FontAwesomeIcon
									icon={faCreditCardBlank}
									size="lg"
								/>
								<div>
									<span>WIC Status</span>
									<p>{client.wicStatus}</p>
								</div>
							</DisplayItem>
						</section>
						<ChildColumn>
							<div>
								<ColumnHeader>
									<h2>Children</h2>
									<ClientActionButton
										type="button"
										onClick={(e) => {
											showAddChild(e);
										}}>
										<FontAwesomeIcon icon={faPlus} />
										<span>Add Child</span>
									</ClientActionButton>
								</ColumnHeader>
								{client.children.items.map((child, key) => {
									return (
										<ChildCard
											key={key}
											child={child}
											setActiveView={setActiveView}>
											<button
												type="button"
												onClick={() => {
													childForm.reset(child);
													setSelectedChild(child);
													setActiveView('editChild');
												}}>
												<FontAwesomeIcon
													icon={faEdit}
												/>
											</button>
										</ChildCard>
									);
								})}
							</div>
							<div>
								<ColumnHeader style={{marginTop: '2rem'}}>
									<h2>Activity</h2>
									<ClientActionButton
										type="button"
										onClick={(e) => {
											setActivityList(!activityList);
										}}>
										{activityList ? (
											<>
												<FontAwesomeIcon icon={faBan} />
												<span>Cancel</span>
											</>
										) : (
											<>
												<FontAwesomeIcon
													icon={faPlus}
												/>
												<span>Add Activity</span>
											</>
										)}
									</ClientActionButton>
								</ColumnHeader>
								{client.activities.length > 0 &&
								activityList === false ? (
									client.activities.map((item, key) => {
										// console.log(item);
										switch (item.activityType) {
											case 'class':
												return (
													<DisplayItem key={key}>
														<FontAwesomeIcon
															icon={
																faGraduationCap
															}
														/>
														<div>
															<span>Class</span>
															<p>{item.name}</p>
															<p>
																{formatDate(
																	new Date(
																		item.completion_date
																	)
																)}
															</p>
														</div>
														<ActivityDeleteButton
															type="button"
															onClick={() => {
																setNotifyProps({
																	message: `Are you sure you want to delete this class?`,
																	confirm:
																		'Delete',
																	cancel: () =>
																		setShowNotify(
																			false
																		),
																	action: () =>
																		deleteClass(
																			item.id
																		),
																});
																setShowNotify(
																	true
																);
															}}>
															<FontAwesomeIcon
																icon={
																	faTrashAlt
																}
															/>
														</ActivityDeleteButton>
													</DisplayItem>
												);
											case 'video':
												return (
													<DisplayItem key={key}>
														<FontAwesomeIcon
															icon={faVideo}
														/>
														<div>
															<span>Video</span>
															<p>
																{item.video_id}
															</p>
															<p>
																{formatDate(
																	new Date(
																		item.date
																	)
																)}
															</p>
														</div>
														<ActivityDeleteButton
															type="button"
															onClick={() => {
																setNotifyProps({
																	message: `Are you sure you want to delete this video?`,
																	confirm:
																		'Delete',
																	cancel: () =>
																		setShowNotify(
																			false
																		),
																	action: () =>
																		deleteVideo(
																			item.id
																		),
																});
																setShowNotify(
																	true
																);
															}}>
															<FontAwesomeIcon
																icon={
																	faTrashAlt
																}
															/>
														</ActivityDeleteButton>
													</DisplayItem>
												);
											case 'help':
												return (
													<DisplayItem key={key}>
														<FontAwesomeIcon
															icon={faHandshake}
														/>
														<div>
															<span>
																Emergency Help
															</span>
															<p>
																{item.helpName}
															</p>
															<p>
																{formatDate(
																	new Date(
																		item.date
																	)
																)}
															</p>
														</div>
														<ActivityDeleteButton
															type="button"
															onClick={() => {
																setNotifyProps({
																	message: `Are you sure you want to delete this help?`,
																	confirm:
																		'Delete',
																	cancel: () =>
																		setShowNotify(
																			false
																		),
																	action: () =>
																		deleteHelp(
																			item.id
																		),
																});
																setShowNotify(
																	true
																);
															}}>
															<FontAwesomeIcon
																icon={
																	faTrashAlt
																}
															/>
														</ActivityDeleteButton>
													</DisplayItem>
												);
											case 'service':
												return (
													<DisplayItem key={key}>
														<FontAwesomeIcon
															icon={faSparkles}
														/>
														<div>
															<span>
																Special Service
															</span>
															<p>
																{
																	item.serviceName
																}
															</p>
															<p>
																{formatDate(
																	new Date(
																		item.date
																	)
																)}
															</p>
														</div>
														<ActivityDeleteButton
															type="button"
															onClick={() => {
																setNotifyProps({
																	message: `Are you sure you want to delete this service?`,
																	confirm:
																		'Delete',
																	cancel: () =>
																		setShowNotify(
																			false
																		),
																	action: () =>
																		deleteSpecialService(
																			item.id
																		),
																});
																setShowNotify(
																	true
																);
															}}>
															<FontAwesomeIcon
																icon={
																	faTrashAlt
																}
															/>
														</ActivityDeleteButton>
													</DisplayItem>
												);
											case 'layette':
												return (
													<DisplayItem key={key}>
														<FontAwesomeIcon
															icon={faGift}
														/>
														<div>
															<span>Layette</span>
															<p>
																{item.layette}
															</p>
														</div>
														<ActivityDeleteButton
															type="button"
															onClick={() => {
																setNotifyProps({
																	message: `Are you sure you want to delete this layette?`,
																	confirm:
																		'Delete',
																	cancel: () =>
																		setShowNotify(
																			false
																		),
																	action: () =>
																		updateLayette(
																			null
																		),
																});
																setShowNotify(
																	true
																);
															}}>
															<FontAwesomeIcon
																icon={
																	faTrashAlt
																}
															/>
														</ActivityDeleteButton>
													</DisplayItem>
												);
											default:
												return ``;
										}
									})
								) : (
									<div>
										<ClientActionButton
											onClick={() =>
												setActiveView('addClass')
											}>
											<FontAwesomeIcon icon={faPlus} />
											<span>Add Class</span>
										</ClientActionButton>
										<ClientActionButton
											onClick={() =>
												setActiveView('addVideo')
											}>
											<FontAwesomeIcon icon={faPlus} />
											<span>Add Video</span>
										</ClientActionButton>
										<ClientActionButton
											onClick={() =>
												setActiveView('addHelp')
											}>
											<FontAwesomeIcon icon={faPlus} />
											<span>Add Emergency Help</span>
										</ClientActionButton>
										<ClientActionButton
											onClick={() =>
												setActiveView('addService')
											}>
											<FontAwesomeIcon icon={faPlus} />
											<span>Add Special Service</span>
										</ClientActionButton>
										<ClientActionButton
											onClick={() => {
												const today = new Date();
												const year = today.getFullYear();
												setNotifyProps({
													message: `Add Layette for ${year}?`,
													confirm: 'Add',
													cancel: () =>
														setShowNotify(false),
													action: () =>
														updateLayette(year),
												});
												setShowNotify(true);
											}}>
											<FontAwesomeIcon icon={faPlus} />
											<span>Add Layette</span>
										</ClientActionButton>
									</div>
								)}
							</div>
						</ChildColumn>
						<ClientAside>
							<ColumnHeader>
								<h2>Notes</h2>
								<ClientActionButton
									type="button"
									onClick={(e) => {
										console.log(e);
										setActiveView('addNote');
									}}>
									<FontAwesomeIcon icon={faPlus} />
									<span>Add Note</span>
								</ClientActionButton>
							</ColumnHeader>
							<div>
								{client.notes.items.map((note, key) => {
									return (
										<NoteCard
											key={key}
											note={note}
											setSelectedNote={setSelectedNote}
											setActiveView={setActiveView}
										/>
									);
								})}
							</div>
						</ClientAside>
					</ClientData>
				</>
			) : null}
			{activeView === 'edit' ? (
				<>
					<ClientEditHeader>
						<ColumnHeader>
							<h1>Edit Client</h1>
							<button
								type="button"
								onClick={(e) => {
									form.reset();
									setActiveView('client');
								}}>
								<FontAwesomeIcon icon={faBan} />
								<span>Cancel</span>
							</button>
						</ColumnHeader>
						<p style={{fontStyle: 'italic'}}>
							Last updated: {client.modified}
						</p>
					</ClientEditHeader>
					<CreateClientForm
						editMode={true}
						client={client}
						form={form}
						setActive={setActiveView}
					/>
				</>
			) : null}
			{activeView === 'addChild' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Child</h1>
						<button
							type="button"
							onClick={(e) => {
								childForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ChildFormComponent
						client={clientId}
						childForm={childForm}
						editMode={false}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'editChild' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Edit Child</h1>
						<button
							type="button"
							onClick={(e) => {
								childForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ChildFormComponent
						client={clientId}
						child={selectedChild}
						childForm={childForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addNote' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Note</h1>
						<button
							type="button"
							onClick={(e) => {
								noteForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<NoteFormComponent
						user={currentUser}
						client={client}
						noteForm={noteForm}
						editMode={false}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'editNote' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Edit Note</h1>
						<button
							type="button"
							onClick={(e) => {
								noteForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<NoteFormComponent
						user={currentUser}
						client={client}
						note={selectedNote}
						noteForm={noteForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addClass' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Class Attendance</h1>
						<button
							type="button"
							onClick={(e) => {
								classForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ClassFormComponent
						user={currentUser}
						client={client}
						classForm={classForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addVideo' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Video</h1>
						<button
							type="button"
							onClick={(e) => {
								videoForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<VideoFormComponent
						user={currentUser}
						client={client}
						videoForm={videoForm}
						editMode={true}
						setActive={setActiveView}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addHelp' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Log Emergency Help</h1>
						<button
							type="button"
							onClick={(e) => {
								helpForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<HelpFormComponent
						user={currentUser}
						client={client}
						helpForm={helpForm}
						editMode={true}
						setActive={setActiveView}
						setActivityList={setActivityList}
					/>
				</ClientAddChild>
			) : null}
			{activeView === 'addService' ? (
				<ClientAddChild>
					<ColumnHeader>
						<h1>Add Special Service</h1>
						<button
							type="button"
							onClick={(e) => {
								helpForm.reset();
								setActiveView('client');
							}}>
							<FontAwesomeIcon icon={faBan} />
							<span>Cancel</span>
						</button>
					</ColumnHeader>
					<ServiceFormComponent
						user={currentUser}
						client={client}
						serviceForm={serviceForm}
						editMode={true}
						setActive={setActiveView}
						setActivityList={setActivityList}
					/>
				</ClientAddChild>
			) : null}
			<Notify props={notifyProps} show={showNotify} />
		</ContentContainer>
	);
};

export default ClientDetail;
