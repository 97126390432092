import * as React from 'react';
import {API} from 'aws-amplify';
import {
	ContentContainer,
	ClientForm,
	DetailItem,
	FieldWrapper,
} from '../Global/StyledComponents';
import Loader from '../Components/Loader';
import * as mutations from '../graphql/mutations';

import {useHistory} from 'react-router-dom';

import {toDateString} from '../lib/helpers';
import {Controller} from 'react-hook-form';
import ReactHookFormSelect from '../lib/ReactHookFormSelect';
import {
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	MenuItem,
} from '@material-ui/core';
import DatePicker from '../Components/DatePicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faPhone,
	faEnvelope,
	faUser,
	faBuilding,
	faInbox,
} from '@fortawesome/free-solid-svg-icons';
import {
	faAddressBook,
	faCreditCardBlank,
	faRingsWedding,
	faUniversity,
} from '@fortawesome/pro-regular-svg-icons';
import {faUserFriends} from '@fortawesome/pro-duotone-svg-icons';

const CreateClientForm = ({client, editMode, form, setActive}) => {
	console.log(client);
	let history = useHistory();
	async function createClient(newClient) {
		const response = await API.graphql({
			query: mutations.createClient,
			variables: {input: newClient},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			history.push(`/clients/${response.data.createClient.id}`);
		}

		return response;
	}

	async function updateClient(attrs) {
		const response = await API.graphql({
			query: mutations.updateClient,
			variables: {input: attrs},
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		});
		console.log(response);
		if (response) {
			setActive('client');
		}
		return response;
	}

	const {control, handleSubmit} = form;

	const onSubmit = (data) => {
		let clientValues = data;
		// console.log(clientValues);
		clientValues.type = 'client';
		if (clientValues.birthyear) {
			clientValues.birthdate = toDateString(
				data.birthmonth,
				data.birthday,
				data.birthyear
			);
		}
		let parsed = Object.keys(clientValues)
			.filter((k) => clientValues[k] != null)
			.reduce((a, k) => ({...a, [k]: clientValues[k]}), {});

		delete parsed.createdAt;
		delete parsed.modified;
		delete parsed.updatedAt;
		delete parsed.birthObj;
		delete parsed.birthmonth;
		delete parsed.birthday;
		delete parsed.birthyear;
		delete parsed.children;
		delete parsed.classes;
		delete parsed.incentives;
		delete parsed.specialServices;
		delete parsed.activities;
		delete parsed.helps;
		delete parsed.videos;
		delete parsed.notes;

		if (!editMode) {
			createClient(parsed);
		} else {
			console.log(parsed);
			updateClient(parsed);
		}
	};

	return !client ? (
		<ContentContainer>
			<Loader />
		</ContentContainer>
	) : (
		<ClientForm onSubmit={handleSubmit(onSubmit)}>
			{/* Profile photo? */}
			<DetailItem>
				<FieldWrapper>
					<FormControlLabel
						label="IRIS Referral"
						control={
							<Controller
								name="iris"
								control={control}
								defaultValue={client.iris ? client.iris : true}
								render={({field}) => (
									<Checkbox
										checked={field.value}
										{...field}
									/>
								)}
							/>
						}
					/>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FontAwesomeIcon icon={faInbox} size="lg" />
				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="intakeStatus-field"
						name="intakeStatus"
						label="Intake Status"
						defaultValue={
							client.intakeStatus !== null
								? client.intakeStatus
								: 'pending'
						}
						variant="outlined">
						<MenuItem value="pending">Pending</MenuItem>
						<MenuItem value="complete">Complete</MenuItem>
					</ReactHookFormSelect>
				</FieldWrapper>
				<FontAwesomeIcon icon={faBuilding} size="lg" />
				<FieldWrapper>
					<ReactHookFormSelect
						control={control}
						id="referringAgency-field"
						name="referringAgency"
						label="Referring Agency"
						defaultValue={
							client.referringAgency !== null
								? client.referringAgency
								: 'none'
						}
						variant="outlined">
						<MenuItem value="none">None</MenuItem>
						<MenuItem value="other">Other</MenuItem>
					</ReactHookFormSelect>
				</FieldWrapper>
			</DetailItem>
			<DetailItem>
				<FieldWrapper>
					<FontAwesomeIcon icon={faUser} size="lg" />

					<Controller
						name="firstName"
						control={control}
						defaultValue={client.firstName || ''}
						render={({field}) => (
							<TextField
								label="First Name"
								variant="outlined"
								type="text"
								autoComplete="foo"
								{...field}
							/>
						)}
					/>
					<Controller
						// id="outlined-basic"
						// className="half"
						control={control}
						name="lastName"
						defaultValue={client.lastName || ''}
						render={({field}) => (
							<TextField
								label="Last Name"
								variant="outlined"
								autoComplete="foo"
								type="text"
								{...field}
							/>
						)}
					/>
				</FieldWrapper>
			</DetailItem>
			<section>
				<DetailItem>
					<FontAwesomeIcon icon={faPhone} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="phone"
							defaultValue={client.phone || ''}
							render={({field}) => (
								<TextField
									label="Phone"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faEnvelope} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="email"
							defaultValue={client.email || ''}
							render={({field}) => (
								<TextField
									label="Email"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>

				<DatePicker
					form={form}
					groupLabel="Date of birth"
					initialValue={client.birthObj}
					nameObj={{
						month: 'birthmonth',
						day: 'birthday',
						year: 'birthyear',
					}}
				/>
				<DetailItem>
					<FontAwesomeIcon icon={faAddressBook} size="lg" />
					<FieldWrapper className="wrap">
						<Controller
							// id="outlined-basic"
							control={control}
							name="address"
							defaultValue={client.address || ''}
							render={({field}) => (
								<TextField
									className="full"
									label="Address"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="city"
							defaultValue={client.city || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="City"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="state"
							defaultValue={client.state || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="State"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
						<Controller
							// id="outlined-basic"
							control={control}
							name="zip"
							defaultValue={client.zip || ''}
							render={({field}) => (
								<TextField
									className="third"
									label="Zip"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faRingsWedding} size="lg" />
					<FieldWrapper>
						<ReactHookFormSelect
							control={control}
							id="maritalStatus-field"
							name="maritalStatus"
							label="Marital Status"
							defaultValue={client.maritalStatus || 'Unknown'}
							variant="outlined">
							<MenuItem value="Unknown">Unknown</MenuItem>
							<MenuItem value="Married">Married</MenuItem>
							<MenuItem value="Unmarried">Unmarried</MenuItem>
						</ReactHookFormSelect>
					</FieldWrapper>
					<FontAwesomeIcon icon={faUserFriends} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="ethnicity"
							defaultValue={client.ethnicity || ''}
							render={({field}) => (
								<TextField
									label="Ethnicity"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				<DetailItem>
					<FontAwesomeIcon icon={faUniversity} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="education"
							defaultValue={client.education || ''}
							render={({field}) => (
								<TextField
									label="Education"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
					<FontAwesomeIcon icon={faCreditCardBlank} size="lg" />
					<FieldWrapper>
						<Controller
							// id="outlined-basic"
							control={control}
							name="wicStatus"
							defaultValue={client.wicStatus || ''}
							render={({field}) => (
								<TextField
									label="WIC Status"
									variant="outlined"
									type="text"
									autoComplete="foo"
									{...field}
								/>
							)}
						/>
					</FieldWrapper>
				</DetailItem>
				{/* <DetailItem>
					<FontAwesomeIcon icon={faStickyNote} size="lg" />
					<FieldWrapper>
						<TextField
							multiline={true}
							rows="5"
							label="Notes"
							type="textarea"
							variant="outlined"
							name="notes"
							value={formik.values.notes || ''}
							onChange={formik.handleChange}
						/>
					</FieldWrapper>
				</DetailItem> */}

				{/* 
					{
						"videos": null,
						"layette": null,
						"helps": null,
						"children": null,
						"incentives": null,
						"description": null,
						"notes": null,
						"createdAt": "2021-04-24T17:16:33.216Z",
						"updatedAt": "2021-04-24T17:16:33.216Z",
						"modified": "April 24, 2021"
					} 
				*/}
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disableElevation>
					Save
				</Button>
			</section>
		</ClientForm>
	);
};

export default CreateClientForm;
