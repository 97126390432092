import * as React from 'react';
import {useAuthState, useAuthDispatch} from '../Context/context';
import {handleSignout} from '../Context/actions';

import Loader from '../Components/Loader';

import {
	NavContainer,
	NavTitle,
	SignOutButton,
} from '../Global/StyledComponents';
import NavButton from '../Components/NavButton';
import {
	faUsers,
	faHome,
	faLandmark,
	faCogs,
	faFile,
	faHandshake,
	faGift,
} from '@fortawesome/free-solid-svg-icons';
import {faSparkles} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOut} from '@fortawesome/pro-regular-svg-icons';

const MenuBar = () => {
	const {user} = useAuthState();
	const dispatch = useAuthDispatch();

	const isAdmin = () => {
		const groups = user.signInUserSession.idToken.payload['cognito:groups'];
		return groups && groups.includes('admins');
	};

	return !user ? (
		<Loader />
	) : (
		<NavContainer>
			<NavTitle>
				<h1>The Treehouse</h1>
			</NavTitle>
			<ul>
				<NavButton
					link="/dashboard"
					label="Dashboard"
					show={true}
					icon={faHome}
				/>
				<NavButton
					link="/clients"
					label="Clients"
					show={true}
					icon={faUsers}
				/>

				<NavButton
					link="/helps"
					label="Emergency Helps"
					show={isAdmin()}
					icon={faHandshake}
				/>
				<NavButton
					link="/pillars"
					label="Pillars"
					show={isAdmin()}
					icon={faLandmark}
				/>
				<NavButton
					link="/incentives"
					label="Incentives"
					show={isAdmin()}
					icon={faGift}
				/>
				<NavButton
					link="/special-services"
					label="Special Services"
					show={isAdmin()}
					icon={faSparkles}
				/>
				<NavButton
					link="/reports"
					label="Reports"
					show={isAdmin()}
					icon={faFile}
				/>
				<NavButton
					link="/admin"
					label="Manage Users"
					show={isAdmin()}
					icon={faCogs}
				/>
				<li style={{marginTop: 'auto'}}>
					<SignOutButton
						type="button"
						onClick={() => handleSignout(dispatch)}>
						Sign Out
						<FontAwesomeIcon icon={faSignOut} />
					</SignOutButton>
				</li>
			</ul>
		</NavContainer>
	);
};

export default MenuBar;
